.report-header {
  top: 0;
  width: 100vw;
  padding: 0.8rem 2.2rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-family: Work Sans;
  position: fixed;
}
.report-header h1 {
  font-size: 2.4rem;
  font-weight: 700;
  color: #2f4cdd;
  margin: 0;
}
.report-header span {
  text-align: center;
  line-height: 2.4rem;
  font-weight: 500;
  font-size: 1.4rem;
  color: #808080;
  margin-bottom: 20rem;
}

@media print {
  @page {
    size: A4;
  }
  body {
    margin: 0;
  }
  .margin {
    margin-bottom: 0 !important;
  }

  .pagebreak {
    page-break-before: always;
  } /* page-break-after works, as well */
  .pagebreakAfter {
    page-break-after: always;
  } /* page-break-after works, as well */
}
