.history-header {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 99px;
  background: #f7f8fe;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 18px 20px 16px 21px;
}

.history-header .title {
  display: flex;
  height: auto;
  margin-bottom: 8px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
}

.history-header .icon-close {
  width: 19px;
  height: 19px;
  float: right;
  opacity: 0.24;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.history-header .descrition {
  height: 30px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

.history-header .resume {
  height: 30px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  display: inline-flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}

.history-header .resume .resume-location {
  width: 100%;
}

.history-header .resume .resume-location > span {
  margin-left: 9px;
}

.history-header .resume .resume-location > span:last-child {
  font-style: italic;
}

.history-header .resume .resume-time {
  width: auto;
  font-weight: 600;
}

.history-content {
  width: 100%;
  height: 193px;
  padding: 24px 20px 16px 21px;
}

.history-content .title {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
}

.period-time {
  height: 45px !important;
}

.period-history {
  width: calc(100% + 5px) !important;
  height: 74px !important;
  display: flex;
  justify-content: center;
}

.period-history > div:first-child {
  width: calc(100% + 20px);
}

.period-history > div:first-child > div:first-child {
  width: calc(100% - 13px);
}

.history-item-list {
  padding: 0 !important;
  border-bottom: none !important;
}

.history-item-list:not(:last-child) {
  padding: 0px 0px 12px !important;
}

.history-item-list .history-time {
  width: 60px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: right;

  color: #4f5882;
}

.history-area {
  width: 100%;
  margin-left: 9px;
  margin-right: 9px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4f5882;
}

/* Ant Design - Slider */
.ant-slider-handle {
  background: #505ea5 !important;
  border: 2px solid #ffffff !important;
  box-sizing: border-box !important;
  box-shadow: 2px 2px 6px rgba(80, 94, 165, 0.13) !important;
  border-radius: 96px !important;
}

.ant-slider-rail {
  top: 5px !important;
  height: 0px !important;
  border: 1px solid #f5f5f5 !important;
}

.ant-slider-track {
  height: 0px !important;
  border: 1.5px solid #505ea5 !important;
  background-color: #505ea5 !important;
}

.ant-tooltip-placement-bottom {
  padding-top: 0px !important;
}

.ant-tooltip-arrow {
  display: none !important;
}

.ant-tooltip-inner {
  padding: 0 8px !important;
  color: #000 !important;
  box-shadow: none !important;
  background-color: transparent !important;
}

/* Mapbox Popup */
.mapboxgl-popup-content {
  width: 244px;
  min-height: 292px;
  color: #4f5882;
  padding: 0 !important;
  border-radius: 10px !important;
  border: none;
  box-shadow: 8px 8px 16px 4px rgba(80, 94, 165, 0.13) !important;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #f7f8fe !important;
}

.mapboxgl-popup-close-button {
  width: 50px !important;
  height: 50px !important;
}
