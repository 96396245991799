.report-header {
    top: 0;
    width: 100vw;
    padding: 0.8rem 2.2rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    font-family: Work Sans;
    position: fixed;
    margin-bottom: 20rem !important;
  }
  .report-header h1 {
    font-size: 2.4rem;
    font-weight: 700;
    color: #2f4cdd;
    margin: 0;
  }
  .report-header span {
    text-align: center;
    line-height: 2.4rem;
    font-weight: 500;
    font-size: 1.4rem;
    color: #808080;
    margin-bottom: 20rem;
  }
  
  @media print {
    @page {
      size: A4;
      margin: 20mm !important;
    }
    body {
      margin: 0;
      font-size: 12px;
    }
    .margin {
      margin-bottom: 0 !important;
    }
  
    .pagebreak {
      page-break-before: auto;
      page-break-inside: avoid;
      page-break-after: auto;
    }

    .pagebreakbar {
      page-break-before: always;
      page-break-inside: avoid;
      page-break-after: auto;
    }
    
    img, table {
      max-width: 100%;
      height: auto;
    }
  }

  .separator {
    border-right: 10px solid #fff !important;
  }
  